<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <CRow>
    <CCol>
      <CForm>
        <ModalParam
          :blockchain_net_id="channel.blockchain_net_id"
          :isShowModal="isShowModalParam"
          :ordererList="modalParam.ordererList"
          :fabricAdminToolList="modalParam.fabricAdminToolList"
          :peerList="modalParam.peerList"
          :submitLabel="$t('common.save')"
          @closeModal="onCloseModal"
          @createParam="onCreateParam"
        />
        <ModalParamAdminTool
          :blockchain_net_id="channel.blockchain_net_id"
          :isShowModal="isShowModalParamAdminTool"
          :fabricAdminToolList="modalParam.fabricAdminToolList"
          :peerList="modalParam.peerList"
          @closeModal="onCloseModal"
          @createParam="onCreateParamAdminTool"
        />
        <CCard>
          <CCardHeader>
            <div class="header d-flex justify-content-between">
              <div class="header__title">
                <strong>{{ $t("channel.transChange") }}</strong>
              </div>
              <div class="header__actions" v-if="transaction">
                <NewUpdateChangeModal
                  class="p-0 m-0"
                  :transaction-id="transaction.id"
                  :update-form="true"
                  :step="transaction.steps[0]"
                  @save="updateTransaction($event)"
                >
                </NewUpdateChangeModal>
              </div>
            </div>
          </CCardHeader>
          <CCardBody>
            <div v-if="!transaction" class="alignCenter">
              <fa-icon
                icon="sync"
                size="1x"
                class="animated"
                style="color: black"
              />
            </div>
            <div v-if="transaction" class="transaction-info mb-2">
              <p class="transaction-info__item item p-0 m-0">
                <span class="item__label"
                  >{{ $t("channel.groupUnderChange") }}:
                </span>
                <span class="item__value">"{{ changedGroupName }}"</span>
              </p>
              <p class="transaction-info__item p-0 m-0">
                <span class="item__label"
                  >{{ $t("channel.channelChangePolicy") }}:
                </span>
                <span class="item__value">{{
                  channelConfigurationUpdateTypeName
                }}</span>
              </p>
              <div class="transaction-info__item p-0 m-0">
                <span class="item__label"
                  >{{ $t("channel.transactionSignatures") }} ({{
                    transaction.signatures.length
                  }}
                  / {{ channel.participants.length }}) :</span
                >
                <ul
                  class="signature-list"
                  v-if="transaction.signatures.length > 0"
                >
                  <li
                    class="signature-list__item"
                    v-for="(item, index) in transaction.signatures"
                    :id="index"
                  >
                    <a
                      v-on:click.prevent="
                        downloadCertificate(item.pem, item.common_name)
                      "
                      href="#"
                      >{{ item.common_name }}</a
                    >
                    <span>({{ item.mspid }})</span>
                  </li>
                </ul>
                <span v-else class="item__value">
                  {{ $t("channel.emptyTransactionSignatures") }}
                </span>
              </div>
            </div>
            <TransactionDiff
              v-if="transaction"
              :oldVal="transactionStep.old_json || ''"
              :newVal="transaction.config_json || ''"
            >
            </TransactionDiff>
          </CCardBody>
          <CCardFooter class="footer-panel">
            <CButton color="dark" variant="outline" @click.prevent="cancel()">
              <div class="icon-wrapper d-flex align-items-center">
                <CIcon name="cil-x-circle" />
                <span class="ml-1">{{ $t("common.cancel") }}</span>
              </div>
            </CButton>
            <CButton
              color="dark"
              :disabled="!transaction || !canImportExport"
              variant="outline"
              @click.prevent="exportTransaction()"
            >
              <div class="icon-wrapper d-flex align-items-center">
                <CIcon name="cil-arrow-thick-from-bottom" />
                <span class="ml-1">{{ $t("common.export") }}</span>
              </div>
            </CButton>
            <import-transaction-modal
              :disabled="!transaction || !canImportExport"
            >
            </import-transaction-modal>
            <CButton
              color="dark"
              :disabled="!transaction || !canRunTransaction"
              variant="outline"
              @click.prevent="run()"
            >
              <div class="icon-wrapper d-flex align-items-center">
                <CIcon name="cil-flight-takeoff" />
                <span class="ml-1">{{ $t("common.start") }}</span>
              </div>
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import utilMessages from "@/utils/messages";
import { createFileNameWithDate, saveFile } from "@/utils/system";

import ImportTransactionModal from "../../common/ImportTransactionModal";
import NewUpdateChangeModal from "../../common/NewUpdateChangeModal";
import {
ChannelConfigUpdateType,
TRANSACTION_QUORUM,
ZERO_TRANZACTION_ID
} from "../../common/transaction/constants";
import TransactionDiff from "../../common/TransactionDiff";
import ModalParam from "../ModalParam.vue";
import ModalParamAdminTool from "./ModalParamAdminTool.vue";

export default {
  name: "ChangeConfiguration",
  components: {
    NewUpdateChangeModal,
    ModalParam,
    ModalParamAdminTool,
    ImportTransactionModal,
    TransactionDiff,
  },
  data() {
    return {
      channel: {
        blockchain_net_id: null,
        participants: [],
      },
      transaction: null,
      topicId: null,
      canSave: true,
      isShowModalParam: false,
      isShowModalParamAdminTool: false,
      modalParam: {
        ordererList: [],
        fabricAdminToolList: [],
        peerList: [],
      },
    };
  },
  async mounted() {
    if (this.$route.query.topic_id) {
      this.topicId = this.$route.query.topic_id;
    }

    if (this.$route.params.id) {
      this.channelId = this.$route.params.id;
      await this.getChannel();
    }
  },
  validations: {},
  computed: {
    ...mapGetters(["getUser"]),
    changedGroupName() {
      return !this.transactionStep ? "" : this.transactionStep?.json_path;
    },
    transactionStep() {
      return this.transaction ? this.transaction.steps[0] : null;
    },
    channelConfigurationUpdateTypeName() {
      return (
        ChannelConfigUpdateType[this.channel.channel_update_policy_type]
          ?.text ?? "-"
      );
    },
    canImportExport() {
      const orAndExclusiveUpdateType = [
        ChannelConfigUpdateType.OR,
        ChannelConfigUpdateType.EXCLUSIVE,
      ];
      return !orAndExclusiveUpdateType.includes(
        this.channel.channel_update_policy_type
      );
    },
    canRunTransaction() {
      switch (this.channel.lifecycle_update_policy_type) {
        case ChannelConfigUpdateType.OR.code:
          return true;
        case ChannelConfigUpdateType.MAJORITY.code:
          const alreadySignedCount = this.transaction.signatures?.length;
          const participantCount = this.channel.participants.length;
          return (
            Math.round((alreadySignedCount / participantCount) * 100) >
            TRANSACTION_QUORUM
          );
        case ChannelConfigUpdateType.EXCLUSIVE.code:
          return (
            this.getUser.org_id === this.channel.channel_administrator_org_id
          );
        default:
          return false;
      }
    },
    transactionHasId() {
      return (
        this.channel.config_update_transaction_id &&
        this.channel.config_update_transaction_id !== ZERO_TRANZACTION_ID
      );
    },
    transactionHasZeroId() {
      return this.channel.config_update_transaction_id === ZERO_TRANZACTION_ID;
    },
  },
  methods: {
    ...mapActions(["subscribe"]),
    async getChannel() {
      try {
        this.channel = await this.$store.dispatch(
          "fetchChannel",
          this.channelId
        );

        if (this.transactionHasZeroId && this.topicId) {
          const subscription = await this.subscribe({
            key: this.topicId,
            handler: (data) => {
              try {
                if (data.success) {
                  this.loadChannel();
                }
              } finally {
                subscription.unsubscribe();
              }
            },
          });

          return;
        }

        await this.fetchTransaction(this.channel.config_update_transaction_id);
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    async loadChannel() {
      await this.getChannel();
    },

    async fetchTransaction(transactionId) {
      try {
        this.transaction = await this.$store.dispatch(
          "fetchTransaction",
          transactionId
        );
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    async fetchTransactions(channelId) {
      try {
        this.transactions = await this.$store.dispatch(
          "fetchObjectTransactions",
          channelId
        );
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    async cancel() {
      try {
        await this.$store.dispatch(
          "CancelUpdateTransactChannel",
          this.channel.id
        );
        this.$router.push({ path: `/channels/${this.channel.id}` });
        this.$toast.success(this.$i18n.t("channel.changeCancelled"));
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    async exportTransaction() {
      try {
        const result = await this.$store.dispatch(
          "exportTransaction",
          this.transaction.id
        );
        const content = JSON.stringify(result);
        const fileName = createFileNameWithDate(this.channel.code_name);
        saveFile(content, fileName);
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    async sign() {
      await this.getFabricAdminTool();
      await this.getPeer();
      this.isShowModalParamAdminTool = true;
    },

    async run() {
      await this.getOrderer();
      await this.getFabricAdminTool();
      await this.getPeer();
      this.isShowModalParam = true;
    },

    onCloseModal() {
      this.isShowModalParam = false;
      this.isShowModalParamAdminTool = false;
    },

    async getOrderer() {
      try {
        const param = `blockchain_net_id=${this.channel.blockchain_net_id}&type=orderer`;
        this.modalParam.ordererList = await this.$store.dispatch(
          "fetchEndpoints",
          param
        );
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    async getFabricAdminTool() {
      try {
        const param = `status=attached&current_org=true&type=fabric-admin-tools`;
        this.modalParam.fabricAdminToolList = await this.$store.dispatch(
          "fetchEndpoints",
          param
        );
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    async getPeer() {
      try {
        const param = `type=peer&current_org=true`;
        this.modalParam.peerList = await this.$store.dispatch(
          "fetchEndpoints",
          param
        );
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    async onCreateParam(param) {
      // handler for onCreateParam (modal)
      // Publishes draft Channel or Updates Channel's transaction
      // Redirects to Topic results page
      param["channel_id"] = this.channel.id;
      try {
        let response = await this.$store.dispatch("executeTransaction", {
          transactionId: this.transaction.id,
          data: param,
        });
        this.$router.push({ path: `/topics/${response.topic_id}` });
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    async onCreateParamAdminTool(param) {
      // handler for onCreateParamAdminTool (modal)
      // Publishes draft Channel or Updates Channel's transaction

      try {
        await this.$store.dispatch("signTransaction", {
          transactionId: this.channel.config_update_transaction_id,
          param: param,
        });
        this.$toast.success(this.$i18n.t("channel.transSigned"));
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    async downloadConf() {
      try {
        const result = await this.$store.dispatch(
          "exportChannel",
          this.channel
        );
        const content = JSON.stringify(result);
        saveFile(content, result.code_name);
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    async updateTransaction(updatedTransaction) {
      let newSteps = [...this.transaction.steps];
      newSteps[0].json_path = updatedTransaction.jsonpath;
      newSteps[0].json = btoa(updatedTransaction.newChange);

      let transactionData = { ...this.transaction };
      transactionData.steps = newSteps;

      const data = {
        transactionId: this.transaction.id,
        data: transactionData,
      };
      try {
        await this.$store.dispatch("updateTransaction", data);
        this.$toast.success(this.$i18n.t("channel.changeAdded"));
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    async addNewStep(step) {
      let steps = [...this.transaction.steps];
      steps.push({
        json_path: step.jsonpath,
        oldJSON: step.old,
        json: btoa(step.newChange),
      });

      await this.saveTransaction(steps);
      await this.fetchTransaction(this.transaction.id);
    },

    readFile() {
      const { file } = this.network;
      let reader = new FileReader();
      reader.readAsText(file);

      reader.onload = () => {
        const json = reader.result;
        this.importNetwork(json);
      };

      reader.onerror = function () {
        this.$toast.error(
          utilMessages.errMessage(
            this.$i18n.t("channel.fileReadError"),
            reader.error
          )
        );
      };
    },
    downloadCertificate(certContent, certName) {
      saveFile(certContent, `${certName}.pem`);
    },
  },
};
</script>

<style>
.object-card-row {
  margin-bottom: 1rem;
}

.footer-panel > button {
  margin-right: 25px;
}

.alignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

#d2h-9 .d2h-file-diff {
  overflow-y: inherit;
}

.signature-list {
  list-style: none;
  padding: 0;
  padding-left: 7px;
}

.item__label {
  font-weight: 700;
}

.item__value {
  color: #838282;
}

.signature-list__item {
  color: #838282;
}
</style>
