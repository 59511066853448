<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
    <div>
        <CModal
            :title="$t('channel.signParams')"
            :show.sync="isShowModal"
            :closeOnBackdrop="false"
            class="hide-close-button"
        >
          <CSelect
            label="Fabric-admin-tool*"
            :options="fabricAdminToolForSelect"
            horizontal
            required
            placeholder=" "
            addLabelClasses="font-weight-bold"
            :value.sync="form.fabricAdminTool"
            :isValid="isValid.fabricAdminTool"
            :tooltipFeedback=false
            :invalidFeedback="validationMessages.required"
            @change="$v.form.fabricAdminTool.$touch()"
          />

          <CSelect
            label="Peer*"
            :options="peerForSelect"
            horizontal
            required
            placeholder=" "
            addLabelClasses="font-weight-bold"
            :value.sync="form.peer"
            :isValid="isValid.peer"
            :tooltipFeedback=false
            :invalidFeedback="validationMessages.required"
            @change="$v.form.peer.$touch()"
          />

          <div slot="footer" class="footer-buttons">
              <CButton
                  type="button"
                  class="btn btn-secondary"
                  @click="closeModal"
              > {{ $t('common.cancel') }} </CButton>
              <CButton
                  type="button"
                  class="btn btn-primary"
                  :disabled="!isFormValid"
                  @click="createParam"
              > {{ $t('common.sign') }} </CButton>
          </div>
      </CModal>
    </div>
</template>

<script>
import {required} from 'vuelidate/lib/validators'
import messages from '../../messages'


export default {
  name: 'ModalParamAdminTool',

  props: {
    isShowModal: Boolean,
    fabricAdminToolList: Array,
    peerList: Array,
  },

  data() {
    return {
      validationMessages: messages.validation,
      form: {
        fabricAdminTool: null,
        peer: null,
      },
    }
  },

  validations: {
    form: {
      fabricAdminTool: {
        required
      },
      peer: {
        required
      },
    }
  },

  computed: {

    isValid() {
      let fabricAdminTool, peer

      if (this.$v.form.fabricAdminTool.$dirty)
        fabricAdminTool = !this.$v.form.fabricAdminTool.$invalid

      if (this.$v.form.peer.$dirty)
        peer = !this.$v.form.peer.$invalid

      return {
        fabricAdminTool,
        peer
      }
    },

    isFormValid() {
      return !this.$v.form.$invalid
    },

    fabricAdminToolForSelect() {
      return this.fabricAdminToolList.map(item => {
        return {
          label: item.hostname,
          value: item.id
        }
      })
    },

    peerForSelect() {
      return this.peerList.map(item => {
        return {
          label: item.hostname,
          value: item.id
        }
      })
    },

  },

  methods: {

    closeModal() {
        this.form = {
          fabricAdminTool: null,
          peer: null,
        }
        this.$v.$reset()
        this.$emit('closeModal')
    },

    createParam() {
      const data = {
        admin_tool_endpoint_id: this.form.fabricAdminTool,
        peer_endpoint_id: this.form.peer,
      }
      this.$emit('createParam', data)
      this.closeModal()
    }

  },
};
</script>

<style scoped>
  .footer-buttons button {
    margin-left: 5px;
  }

</style>
